import React from 'react';
import Banner from './Banner';

const BannerContainer = () => {
  return (
    <section className="navigation-container" aria-label="navigation">
      <Banner />
      {/*<Breadcrumbs />*/}
    </section>
  );
};

export default BannerContainer;
