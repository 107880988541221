import React, {FC, useContext, useEffect, useState} from 'react';
import cx from 'classnames';
import {ConfigContext} from "../contexts";

interface IProps {
  preview: boolean;
  text: string;
  id: string;
}
const getAnimationWidth = id => {
  return document.getElementsByClassName(`scrolling-route-longName-${id}`)[0].clientWidth
      - document.getElementsByClassName(`grid-header destination`)[0].clientWidth
      + 5; //to let text end scroll out of shadow
};

const RouteLongNameScrolling: FC<IProps> = ({
  preview,
  text,
  id,
}) => {
  const [animationWidth, setAnimationWidth] = useState(0);
  const [speed, setSpeed] = useState(0);
  const [update, setUpdate] = useState(false);
  const config = useContext(ConfigContext);
  const scrollSpeed = config.routeNameScrollSpeed;

  const updateAnimation = () => {
    const width = getAnimationWidth(id);
    setAnimationWidth(width);
    setSpeed(width/scrollSpeed);
    setUpdate(true);
  };
  // ---------------------------------
  useEffect(() => {
    updateAnimation();
    const to = setTimeout(() => setUpdate(false), 100);
    window.addEventListener('resize', () => {
      updateAnimation();
      setTimeout(() => setUpdate(false), 100);
    });
    return () => clearTimeout(to);
  }, []);

  const style = {
    '--animationWidth': `${Number(-1 * animationWidth).toFixed(0)}px`,
    '--speed': `${Number(speed).toFixed(0)}s`,
  } as React.CSSProperties;
  return (
    <div style={style} className={cx('grid-row', 'route-name', 'horizontal')}>
      <div className={cx('grid-cols', 'alert-row')} style={{padding: 0}}>
      {/*<div>*/}
        <div
          className={cx('alert-text', {
            animated: !update,
          })}
          onAnimationIteration={() => {
            updateAnimation();
            requestAnimationFrame(() => setUpdate(false));
          }}
        >
          <span key={`longName-${id}`} className={`single-alert scrolling-route-longName-${id}`}>
            {text}
          </span>
        </div>
      </div>
    </div>
  );
};

export default RouteLongNameScrolling;
