import * as React from 'react';
import png from './logo_1_valge.png';

export default () => {
  return (
      <div
          className="logo"
          style={{ backgroundImage: `url(${png})`,
              backgroundPosition: 0,
              backgroundSize: 'contain',
              minHeight: '38px',
              minWidth: '9em'}}
      />
  );
};
