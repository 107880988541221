import React, {FC} from 'react';
import cx from 'classnames';
import PeatusLogo from './PeatusLogo';

interface ICommonProps {
  readonly isPreview?: boolean;
  readonly isLandscape?: boolean;
  readonly forMonitor?: boolean;
}

const Logo: FC<ICommonProps> = props => {
  const { isPreview, isLandscape } = props;
  let isHsl = false;
    return (
      <div
        className={cx(
          'title-logo',
          isPreview ? 'preview' : '',
          isLandscape ? '' : 'portrait',
          isHsl ? 'hsl' : '',
        )}
      >
        <PeatusLogo />
      </div>
    );
  return null;
};

export default Logo;
